<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <div style="padding: 1em" class="container">
      <div class="mx-2">
        <div class="d-flex justify-content-between items-center mb-3">
          <div class="text-start">
            <div class="fw-bolder">{{ companyName }}</div>
            <div>{{ moment().format("DD MMMM YYYY") }}</div>
          </div>

          <button
            v-if="!isNativeApp"
            class="btn-outline primary-btn d-flex my-1"
            @click="onExport"
          >
            <b-icon-upload font-scale="1.5" style="margin-right: 5px" />
            <p class="m-0">{{ $t("depreciation.export_file") }}</p>
          </button>
        </div>

        <AdminDashboard v-if="isBorrowReturnAdmin" />
        <UserDashboard v-else />

        <div class="borrow-bar-graph">
          <div class="d-flex justify-content-between flex-wrap">
            <h4 class="fw-normal">{{ $t("borrow.number_times_borrow") }}</h4>
            <div class="d-flex align-items-center">
              <SelectDropdown
                :options="yearOptions"
                @select="handleYearFilter"
              />
              <div class="position-relative m-2" style="height: fit-content">
                <input
                  v-model.trim="filterOptions.text"
                  type="text"
                  :placeholder="$t('borrow.search')"
                  class="form-control"
                  style="padding: 0.75rem 0.75rem 0.75rem 30px; background: #E5E7E7 !important;"
                  v-debounce:700ms="searchByText"
                />
                <img
                  class="position-absolute top-50 translate-middle-y"
                  style="left: 5px"
                  src="../../assets/search-white.svg"
                  width="20px"
                />
              </div>
              <button
                class="filter flex-shrink-0"
                type="button"
                id="button-addon2"
                @click="filterModal = true"
              >
                <img src="@/assets/filter-white.svg" />
              </button>
            </div>
          </div>
          <apexchart
            type="bar"
            height="300px"
            :options="options"
            :series="series"
          ></apexchart>
        </div>
        <small class="d-block text-end fw-light">{{
          formatedReportDateText
        }}</small>
      </div>

      <asset-filter
        :showModal="filterModal"
        @confirm-action="handleFilterConfirm"
        @hide-modal="filterModal = false"
        @cancel-action="handleClearFilter"
      ></asset-filter>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import AdminDashboard from "../../components/BorrowReturn/AdminDashboard.vue";
import UserDashboard from "../../components/BorrowReturn/UserDashboard.vue";
import AssetFilter from "../../components/BorrowReturn/AssetFilter.vue";
import SelectDropdown from "@/components/BorrowReturn/SelectDropdown.vue";

import { mapGetters } from "vuex";
import axios from "axios";
import { authHeader } from "../../store/actions";
import { borrowUrl } from "@/util/backend";
export default {
  components: {
    Loading,
    AdminDashboard,
    UserDashboard,
    AssetFilter,
    apexchart: VueApexCharts,
    SelectDropdown,
  },
  data() {
    return {
      isLoading: false,
      selectedYear: moment().year(),
      filterModal: false,
      filterOptions: {
        text: null,
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
      },
      options: {
        chart: {
          id: "borrow-history",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: this.$t("adddate.month_short"),
        },
      },
      series: [
        {
          name: this.$t("borrow.number_times_borrow"),
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      companyName: "companyName",
      isBorrowReturnAdmin: "isBorrowReturnAdmin",
    }),
    yearOptions() {
      const currentYear = moment().year();
      const years = [];
      for (let i = 0; i < 5; i++) {
        years.push(currentYear - i);
      }
      return years;
    },
    formatedReportDateText() {
      const now = moment();
      return this.$t("borrow.report_date_format", {
        fullDate: now.format("dddd DD MMMM YYYY"),
        time: now.format("HH:mm A"),
        timeZone: now.format("Z"),
      });
    },
  },
  methods: {
    moment,
    handleYearFilter(value) {
      this.selectedYear = value;
      this.fetchBorrowReturnYearsStatics();
    },
    handleFilterConfirm(filterOptions) {
      this.filterModal = false;
      this.filterOptions = { ...filterOptions, text: this.filterOptions.text };
      this.fetchBorrowReturnYearsStatics();
    },
    searchByText() {
      this.fetchBorrowReturnYearsStatics();
    },
    handleClearFilter(filterOptions) {
      this.filterModal = false;
      this.filterOptions = { ...filterOptions };
      this.filterOptions.text = null;
      this.fetchBorrowReturnYearsStatics();
    },
    resetYearStatics() {
      this.series = [
        {
          ...this.series[0],
          data: Array.from({ length: 12 }, () => 0),
        },
      ];
    },
    async fetchBorrowReturnYearsStatics() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");

      this.isLoading = true;
      try {
        const res = await axios.get(
          `${borrowUrl()}dashboard/borrow/year/${
            this.selectedYear
          }/company/id/${id_company}`,
          {
            params: {
              ...this.filterOptions,
              text: this.filterOptions.text,
            },
            ...authHeader(),
          }
        );
        const data = res.data;

        if (!data || data.length <= 0) {
          this.resetYearStatics();
          this.isLoading = false;
          return;
        }

        const newStaticSeries = [...this.series];
        for (let i = 0; i < data.length; i++) {
          const staticsData = data[i];
          const monthIdx = staticsData.month - 1;
          newStaticSeries[0].data[monthIdx] = staticsData.count;
        }
        this.series = newStaticSeries;
      } catch (err) {
        console.error(err);
      }
      this.isLoading = false;
    },
    async onExport() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await axios.get(
          `${borrowUrl()}dashboard/excel/year/${
            this.selectedYear
          }/company/id/${id_company}`,
          { ...authHeader(), responseType: "blob" }
        );
        let headerLine = res.headers["content-disposition"];
        headerLine = decodeURIComponent(headerLine);
        const matches = /filename[^;=\n]*=(UTF-8['"]*)?([^;\n]*)/gi.exec(
          headerLine
        );
        const filename = (matches && matches[2]) || "Borrow_Dashboard.xlsx";
        const elink = document.createElement("a");
        elink.download = filename;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(res.data);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err);
        }
      }
    },
  },
  async created() {
    this.fetchBorrowReturnYearsStatics();
  },
};
</script>
<style lang="scss" scoped>
.text-grey {
  color: #7c7c7c;
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  border: none;
  width: 40px;
  height: 40px;
  img {
    padding: 0.3rem;
    width: 40px;
  }
}

.donut-section {
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  // margin: 10px 0;
}

.donut-container {
  max-width: 360px;
}

#donut-chart {
  width: 100%;
}

.borrow-bar-graph {
  background: #fff;
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 20px;
  margin: 2rem 0;
}

.dots {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  &.free {
    background-color: #92defb;
  }
  &.occupied {
    background-color: #f5bfc6;
  }
  &.not-ready {
    background-color: #c0c0fa;
  }
}
</style>
